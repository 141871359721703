var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-4"},[_c('v-row',{staticClass:"pa-2"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"dense":"","items":_vm.itemsStatus,"label":"Status","item-text":"label","item-value":"value","hide-details":""},model:{value:(_vm.filterClient.status),callback:function ($$v) {_vm.$set(_vm.filterClient, "status", $$v)},expression:"filterClient.status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"dense":"","items":_vm.itemsTest,"label":"Teste","item-text":"label","item-value":"value","hide-details":""},model:{value:(_vm.filterClient.test),callback:function ($$v) {_vm.$set(_vm.filterClient, "test", $$v)},expression:"filterClient.test"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"dense":"","items":_vm.itemsResale,"label":"Revenda","item-text":"name","item-value":"id","hide-details":""},model:{value:(_vm.filterClient.resale),callback:function ($$v) {_vm.$set(_vm.filterClient, "resale", $$v)},expression:"filterClient.resale"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"dense":"","items":_vm.itemsModality,"label":"Modalidade","item-text":"label","item-value":"value","hide-details":""},model:{value:(_vm.filterClient.modality),callback:function ($$v) {_vm.$set(_vm.filterClient, "modality", $$v)},expression:"filterClient.modality"}})],1)],1)],1),_c('v-divider',{staticClass:"my-4",attrs:{"dark":""}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","show-select":"","sort-by":"Ordenar por","headers":_vm.headers,"items":_vm.clients,"items-per-page":10,"loading":_vm.loading,"search":_vm.filter.search,"loading-text":"Carregando... Por favor aguarde","header-props":{
      sortByText: 'Ordenar por',
    },"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
      itemsPerPageAllText: 'Todos',
      'items-per-page-text': 'Clientes por pagina',
    }},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_vm._v(" Nenhum cliente encontrado ")]},proxy:true},{key:"top",fn:function(){return [_c('v-row',{staticClass:"py-3",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('div',{staticClass:"text-h6 ml-2"},[_vm._v("Lista de clientes")])]),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"6"}},[_c('v-text-field',{staticClass:"mx-2",attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":"","dense":""},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('v-col',{staticClass:"d-flex justify-space-around",attrs:{"cols":"12","sm":"4","md":"3"}},[(!_vm.$vuetify.breakpoint.xs)?_c('v-btn',{attrs:{"color":"primary","dark":"","to":"/cliente/adicionar"}},[_vm._v(" Novo cliente ")]):_vm._e(),(!_vm.$vuetify.breakpoint.xs && _vm.selected.length > 0)?_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue-grey","dark":""}},'v-btn',attrs,false),on),[_vm._v(" ALTERAR ")])]}}],null,false,3868305961),model:{value:(_vm.dialogAlteration),callback:function ($$v) {_vm.dialogAlteration=$$v},expression:"dialogAlteration"}},[_c('ModalAlteration',{attrs:{"clients-ids":_vm.selected.map(function (se) { return se.id; })},on:{"closeModal":_vm.closeModalAlteration,"finnalyModal":_vm.finallyModalAlteration}})],1):_vm._e()],1)],1)]},proxy:true},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(!_vm.selected.includes(item))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!_vm.selected.includes(item))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(!_vm.selected.includes(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.license(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-list-box ")])]}}],null,true)},[_c('span',[_vm._v("Licenças")])]):_vm._e()]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:item.blocked ? ['red--text', 'font-weight-bold'] : null},[_vm._v(_vm._s(item.id))])]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:item.blocked ? ['red--text', 'font-weight-bold'] : null},[_vm._v(_vm._s(item.name))])]}},{key:"item.cnpj",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:item.blocked ? ['red--text', 'font-weight-bold'] : null},[_vm._v(_vm._s(item.cnpj))])]}},{key:"item.address",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:item.blocked ? ['red--text', 'font-weight-bold'] : null},[_vm._v(_vm._s(item.address)+", "+_vm._s(item.number))])]}},{key:"item.state",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:item.blocked ? ['red--text', 'font-weight-bold'] : null},[_vm._v(_vm._s(item.state))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.$vuetify.breakpoint.xs)?_c('v-btn',{attrs:{"fab":"","color":"primary","bottom":"","fixed":"","right":"","to":"/cliente/adicionar"}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }