<template>
  <v-container fluid>
    <v-card class="mt-4">
      <v-row class="pa-2">
        <v-col cols="12" md="3">
          <v-select
            dense
            v-model="filterClient.status"
            :items="itemsStatus"
            label="Status"
            item-text="label"
            item-value="value"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            dense
            v-model="filterClient.test"
            :items="itemsTest"
            label="Teste"
            item-text="label"
            item-value="value"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            dense
            v-model="filterClient.resale"
            :items="itemsResale"
            label="Revenda"
            item-text="name"
            item-value="id"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            dense
            v-model="filterClient.modality"
            :items="itemsModality"
            label="Modalidade"
            item-text="label"
            item-value="value"
            hide-details
          ></v-select>
        </v-col>
      </v-row>
    </v-card>
    <v-divider dark class="my-4"></v-divider>
    <v-data-table
      dense
      v-model="selected"
      show-select
      sort-by="Ordenar por"
      :headers="headers"
      :items="clients"
      :items-per-page="10"
      class="elevation-1"
      :loading="loading"
      :search="filter.search"
      loading-text="Carregando... Por favor aguarde"
      :header-props="{
        sortByText: 'Ordenar por',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageAllText: 'Todos',
        'items-per-page-text': 'Clientes por pagina',
      }"
    >
      <template v-slot:no-results> Nenhum cliente encontrado </template>
      <template v-slot:top>
        <v-row justify="center" align="center" class="py-3">
          <v-col cols="12" sm="3" md="3">
            <div class="text-h6 ml-2">Lista de clientes</div>
          </v-col>
          <v-col cols="12" sm="5" md="6">
            <v-text-field
              class="mx-2"
              v-model="filter.search"
              append-icon="mdi-magnify"
              label="Pesquisa"
              single-line
              hide-details
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="3" class="d-flex justify-space-around">
            <v-btn
              color="primary"
              dark
              to="/cliente/adicionar"
              v-if="!$vuetify.breakpoint.xs"
            >
              Novo cliente
            </v-btn>
            <v-dialog
              v-if="!$vuetify.breakpoint.xs && selected.length > 0"
              v-model="dialogAlteration"
              persistent
              max-width="1000px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="blue-grey" dark v-bind="attrs" v-on="on">
                  ALTERAR
                </v-btn>
              </template>
              <ModalAlteration
                v-bind:clients-ids="selected.map((se) => se.id)"
                @closeModal="closeModalAlteration"
                @finnalyModal="finallyModalAlteration"
              />
            </v-dialog>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="!selected.includes(item)"
          small
          class="mr-2"
          @click="edit(item.id)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="!selected.includes(item)"
          small
          class="mr-2"
          @click="remove(item)"
        >
          mdi-delete
        </v-icon>
        <v-tooltip bottom v-if="!selected.includes(item)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click="license(item.id)" v-bind="attrs" v-on="on">
              mdi-list-box
            </v-icon>
          </template>
          <span>Licenças</span>
        </v-tooltip>
      </template>
      <template v-slot:item.id="{ item }">
        <span
          :class="item.blocked ? ['red--text', 'font-weight-bold'] : null"
          >{{ item.id }}</span
        >
      </template>
      <template v-slot:item.name="{ item }">
        <span
          :class="item.blocked ? ['red--text', 'font-weight-bold'] : null"
          >{{ item.name }}</span
        >
      </template>
      <template v-slot:item.cnpj="{ item }">
        <span
          :class="item.blocked ? ['red--text', 'font-weight-bold'] : null"
          >{{ item.cnpj }}</span
        >
      </template>
      <template v-slot:item.address="{ item }">
        <span :class="item.blocked ? ['red--text', 'font-weight-bold'] : null"
          >{{ item.address }}, {{ item.number }}</span
        >
      </template>
      <template v-slot:item.state="{ item }">
        <span
          :class="item.blocked ? ['red--text', 'font-weight-bold'] : null"
          >{{ item.state }}</span
        >
      </template>
    </v-data-table>
    <v-btn
      fab
      color="primary"
      bottom
      fixed
      right
      v-if="$vuetify.breakpoint.xs"
      to="/cliente/adicionar"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import { mapState } from "vuex";
import ModalAlteration from "./ModalAlteration";
export default {
  name: "Client",
  components: { ModalAlteration },
  computed: mapState(["filterClient"]),
  data() {
    return {
      dialogAlteration: false,
      selected: [],
      clients: [],
      loading: false,
      search: "",
      filter: {
        status: null,
        test: null,
        resale: null,
        search: "",
        modality: null,
      },
      headers: [
        { text: "#", value: "id" },
        { text: "Nome", align: "start", value: "name" },
        { text: "CNPJ", value: "cnpj", sortable: false },
        { text: "Endereço", value: "address", sortable: false },
        { text: "UF", value: "state", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      itemsStatus: [
        { label: "TODOS", value: null },
        { label: "DESBLOQUEADO", value: 0 },
        { label: "BLOQUEADO", value: 1 },
      ],
      itemsTest: [
        { label: "TODOS", value: null },
        { label: "NÃO", value: 0 },
        { label: "SIM", value: 1 },
      ],
      itemsResale: [{ name: "TODOS", id: null }],
      itemsModality: [
        { label: "TODOS", value: null },
        { label: "MENSALISTA", value: "monthly" },
        { label: "ANUAL", value: "yearly" },
      ],
    };
  },
  methods: {
    getClients() {
      this.loading = true;
      const url = `${baseApiUrl}/clients`;
      this.$store.commit("setFilterClient", this.filter);
      localStorage.setItem("__rfsadmin_filter", JSON.stringify(this.filter));
      axios.get(url, { params: this.filter }).then((res) => {
        this.clients = res.data;
        this.loading = false;
      });
    },
    getResales() {
      const url = `${baseApiUrl}/resales`;
      axios.get(url).then((res) => {
        this.itemsResale = res.data;
        this.itemsResale.unshift({ name: "TODOS", id: null });
      });
    },
    edit(id) {
      this.$router.push(`/cliente/${id}`);
    },
    license(id) {
      this.$router.push(`/cliente/${id}/contrato`);
    },
    remove(client) {
      if (confirm(`Tem certeza que deseja excluir o cliente ${client.name}?`)) {
        axios
          .delete(`${baseApiUrl}/client/${client.id}`)
          .then(() => {
            this.getClients();
          })
          .catch((error) => alert(error));
      }
    },
    closeModalAlteration() {
      this.dialogAlteration = false;
    },
    finallyModalAlteration() {
      this.dialogAlteration = false;
      this.getClients();
      this.selected = [];
    },
  },
  watch: {
    filter: {
      handler() {
        this.getClients();
      },
      deep: true,
    },
  },
  mounted() {
    const json = localStorage.getItem("__rfsadmin_filter");
    if (json != null) {
      const filterdata = JSON.parse(json);
      this.filter = filterdata;
    }
    this.getClients();
    this.getResales();
    // console.log(this.$vuetify.breakpoint.width);
    // console.log(this.$vuetify.breakpoint.mobile);
    // console.log(this.$vuetify.breakpoint.xs);
  },
};
</script>
<style>
@media (max-width: 599px) {
  .v-data-footer__select {
    margin-left: 0 !important;
    margin-right: 50px !important;
  }
  .v-data-footer__pagination {
    margin-left: 30px !important;
  }
}
</style>