<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Alterações Cliente</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" md="4" xl="5">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMomentjs"
                  label="Expiração PDV"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="client.expirationAt"
                :allowed-dates="allowedDates"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" xl="5">
            <v-menu
              v-model="menuPr"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDatePrFormattedMomentjs"
                  label="Expiração PR"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="client.expirationPrAt"
                :allowed-dates="allowedDates"
                @input="menuPr = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" xl="2">
            <v-switch
              v-model="client.blocked"
              color="red"
              label="Bloqueado?"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeModal()"> FECHAR </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="save()"
        :disabled="!verifyAlteration"
      >
        ALTERAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { baseApiUrl } from "@/global";
moment.locale("pt-br");
export default {
  name: "ModalAlteration",
  props: {
    clientsIds: Array,
  },
  data: () => ({
    menu: false,
    menuPr: false,
    client: {},
  }),
  methods: {
    closeModal() {
      this.client = {};
      this.$emit("closeModal");
    },
    finnalyModal() {
      this.$emit("finnalyModal");
    },
    allowedDates: (val) => moment(val).day() !== 0 && moment(val).day() !== 6,
    async save() {
      const url = `${baseApiUrl}/clients/`;
      this.client.ids = this.clientsIds;
      axios["put"](url, this.client)
        .then(() => {
          this.finnalyModal();
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data);
          }
        });
    },
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.client.expirationAt
        ? moment(this.client.expirationAt).format("dddd, ll")
        : "";
    },
    dateFormatted() {
      return moment(this.client.expirationAt).format();
    },
    computedDatePrFormattedMomentjs() {
      return this.client.expirationPrAt
        ? moment(this.client.expirationPrAt).format("dddd, ll")
        : "";
    },
    datePrFormatted() {
      return moment(this.client.expirationPrAt).format();
    },
    verifyAlteration() {
      return (
        this.client.expirationPrAt ||
        this.client.expirationAt ||
        this.client.blocked != undefined
      );
    },
  },
};
</script>

<style>
</style>